import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'
import {FontLoader} from 'three/examples/jsm/loaders/FontLoader.js'
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry.js'


/**
 * Base
 */
// Debug
// const gui = new GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
scene.background = new THREE.Color(0xffffff)

// Axes helper

const axesHelper = new THREE.AxesHelper()
// scene.add(axesHelper)

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matcapTextureNeuquen = textureLoader.load('/textures/matcaps/Matcap.png')
const matcapTextureCapital = textureLoader.load('/textures/matcaps/Matcap (1).png')

matcapTextureNeuquen.colorSpace = THREE.SRGBColorSpace

matcapTextureCapital.colorSpace = THREE.SRGBColorSpace

/* Fonts */
const fontLoader = new FontLoader()
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) => {
        const textGeometry = new TextGeometry(
            'neuquen',
            {
                font: font,
                size: 0.5,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffet: 0,
                bevelSegments: 4,
            }
        )
        const textGeometryCapital = new TextGeometry(
            'capital',
            {
                font: font,
                size: 0.5,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffet: 0,
                bevelSegments: 4
            }
        )

        textGeometry.computeBoundingBox()
        textGeometryCapital.computeBoundingBox()

        textGeometry.translate(
            - textGeometry.boundingBox.max.x * 0.5,
            - textGeometry.boundingBox.max.y * 0.5,
            - textGeometry.boundingBox.max.z * 0.5,
        )

        textGeometryCapital.translate(
            - textGeometry.boundingBox.max.x * 1,
            - textGeometry.boundingBox.max.y * 1,
            - textGeometry.boundingBox.max.z * 0.5,
        )

        const textMaterial = new THREE.MeshMatcapMaterial({matcap: matcapTextureNeuquen })
        // {color: '#354c99'}
        
        const text = new THREE.Mesh(textGeometry, textMaterial)
        
        const textCapitalMaterial = new THREE.MeshMatcapMaterial({matcap: matcapTextureCapital })
        // {color: '#34b3e7'}
        const textCapital = new THREE.Mesh(textGeometryCapital, textCapitalMaterial)
        text.position.y = 0
        textCapital.position.y = -0.8
        
        scene.add(text, textCapital)
    }
)

/**
 * Object
 */
const cube = new THREE.Mesh(
    new THREE.BoxGeometry(1, 1, 1),
    new THREE.MeshBasicMaterial()
)

// scene.add(cube)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', ()  =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 1
camera.position.y = 1
camera.position.z = 5
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()